import React from 'react'
import AskedQuestions from './AskedQuestions'

const ServiceApresVente = () => {
  return (
    <div>
      <AskedQuestions/>
    </div>
  )
}

export default ServiceApresVente
